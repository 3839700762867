import { MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
const __default__ = defineComponent({
    components: {
        MoreFilled,
        PopOverSlot,
        ProfileVerifiedIcon
    },
    emits: ['handle-event'],
    props: {
        title: {
            type: String,
        },
        cardHeight: {
            type: String,
            default: '300px'
            // default: 'auto'
        },
        showDropdownMenu: {
            type: Boolean,
            default: true
        },
        showSoleTraderStatus: {
            type: Boolean,
            default: false
        },
        popOverData: {
            type: Object
        },
        user: {
            type: Object,
        },
        removeShadow: {
            type: Boolean,
            default: false
        },
        hasCustomHeader: {
            type: Boolean,
            default: false
        },
        hasCustomMiddleHeader: {
            type: Boolean,
            default: false
        },
        isProfileTeam: {
            type: Boolean,
            default: false
        },
        padding: {
            type: String,
            default: '1.9rem'
        }
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        isVerified() {
            return this.user?.userIdentification?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        userProfileState() {
            return this.activeUserWorkstation?.verificationStatus;
        },
        statusNotAdded() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.NOT_ADDED;
        },
        statusPending() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        },
        statusVerified() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        statusRejected() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.REJECTED;
        },
        hasSetupProfile() {
            if (this.user?.userCertificates.length > 0 || this.user?.userInsurances.length > 0 || this.user?.userSkills.length > 0) {
                return true;
            }
            return false;
        }
    },
    methods: {
        handleEvent(eventName) {
            this.$emit('handle-event', eventName);
        },
    }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "fb1da688": (_ctx.$props.cardHeight),
        "084bddcc": (_ctx.$props.padding)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
