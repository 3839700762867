<template>
    <el-popover :placement="placement ? placement : 'left-start'" width="auto" trigger="click">
        <div class="options flex-column">
            <div class="options__option flex-row ai-fs"
              v-for="(item, index) in data" :key="index"
              @click="handleFunctionCall(item?.event, item?.name, item?.id)">
              <inline-svg class="download-icon" width="24" height="24" :src="item?.icon"/>
              <p class="options__label">{{ item?.name }}</p>
            </div>
        </div>
        <template #reference>
            <slot></slot>
        </template>
    </el-popover>
</template>
<script>
// import { computed } from 'vue';
// import { useRouter } from 'vue-router';
import { mapActions } from 'vuex';

import { USER_DOCUMENTS_CACHE } from '@/store/modules/attachment-cache/constants';
import { DOCUMENTS_STORE } from '@/store/modules/documents';

import { ATTACHMENT_CACHE_STORE } from '../../../store/modules/attachment-cache';

export default {
  emits: ['handle-click'],
  props: ['data', 'document', 'placement'],
  // setup() {
  //   const routeName = computed(() => {
  //     return useRouter().currentRoute.value.name;
  //   });

  //   return {
  //     routeName,
  //   };
  // },

  computed: {
    routeName() {
      return this.$route.name;
    },
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachment']),
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenShareModal',
      'setOpenRenameModal',
      'setOpenMoveModal',
      'setOpenDetailsModal',
      'setOpenDeleteModal',
      'setOpenNewFolderModal',
      'setOpenNewFileUploadModal',

      'setSelectedDocument'
    ]),
    openModalNewFolder() {
      if (this.routeName === 'documents') {
        this.setOpenNewFolderModal(true);
      } else {
        this.$router.push('documents');
        this.setOpenNewFolderModal(true);
      }
    },
    openFileUploadModal() {
      if (this.routeName === 'documents') {
        this.setOpenNewFileUploadModal(true);
      } else {
        this.$router.push('documents');
        this.setOpenNewFileUploadModal(true);
      }
    },
    openModalShare() {
      this.setOpenShareModal(true);
    },
    openModalRename() {
      this.setOpenRenameModal(true);
    },
    openModalMove() {
      this.setOpenMoveModal(true);
    },
    openModalDetails() {
      this.setOpenDetailsModal(true);
    },
    openModalDelete() {
      this.setOpenDeleteModal(true);
    },
    routeToDocuments() {
      this.$router.push('documents');
    },
    routeToPropertyListings() {
      this.$router.push('property-listing');
    },
    routeToAddProperty() {
      this.$router.push('properties');
    },
    routeToProjects() {
      this.$router.push('projects');
    },
    routeToSelectPropertyPage() {
      this.$router.push('select-property');
    },
    handleFunctionCall(functionName, name, id) {
      if (this.document) {
        this.setSelectedDocument(this.document);
      }

      if (!functionName || functionName === null || functionName === '') {
        this.$emit('handle-click', id !== undefined ? id : name);
      } else {
        this[functionName]();
      }
    },

    async downloadDocument() {
      const { document } = this;
      const { originalName, isDir, attachment: keyDoc } = document;

      if (!isDir) {
        const attachment = await this.getAttachment({
          name: USER_DOCUMENTS_CACHE,
          attachment: keyDoc,
          originalName,
          includeUrl: true
        });

        if (attachment && attachment.url) {
          window.open(attachment.url);
        } else {
          // eslint-disable-next-line no-alert
          alert('File not found!');
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Downloading of folder is disallowed for the moment!');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.el-popover {
  width: auto;
}
.options {
  &__option {
    cursor: pointer;
    padding: .3rem;
    border-radius: 4px;
    &:hover {
        background: rgba(255, 165, 0, 0.1);
        .options__label {
            color: #FFA500;
        }
    }

    &:last-child {
        position: relative;
        margin-top: 1rem;
        &::before {
            content: '';
            background: rgba(12, 15, 74, 0.2);
            position: absolute;
            top: -1rem;
            left:0;
            right:0;
            width: 100%;
            height: 1px;
        }
    }
  }

  &__label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
    // white-space: nowrap;
  }
}
</style>
